import { createComponent, Slot } from '@lib/util/templateHelpers';

import CategoryMenuList from '@/components/CategoryMenuList';
import CollapsableSidebarLayout from '@/layouts/CollapsableSidebarLayout';

export default createComponent('GameTileLayout', {}, function GameTileLayout ({}, props) {
	const { categories } = props;
  return (
    <CollapsableSidebarLayout hasBannerAd>
      <Slot name='sidebarHeader'>
        Categories
      </Slot>

      <Slot name='sidebar'>
        <CategoryMenuList categories={props.categories} />
      </Slot>

      {props.children}
    </CollapsableSidebarLayout>
  );
});
